import store from "@/store";
import router from "@/router";
import {Buffer} from "buffer";

class TokenLogic {
    static instance = null;
    static createInstance() {
        var object = new TokenLogic();
        return object;
    }

    static getInstance() {
        if (!TokenLogic.instance) {
            TokenLogic.instance = TokenLogic.createInstance();
        }
        return TokenLogic.instance;
    }

    constructor() {
    }

    async request(objCredentials={}){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;
            var ROUTE_ACCESSTYPE = currentRoute.meta.routeAccessType;

            if(CommonHelper.isEmptyObject(objCredentials)){
                var encryptedEmail = Buffer.from(process.env.APITOKEN_USER).toString('base64');
                var encryptedClave = Buffer.from(process.env.APITOKEN_PASSWORD).toString('base64');

                objCredentials = {
                    email: encryptedEmail,
                    clave: encryptedClave,
                }
            } else {
                if(ROUTE_ACCESSTYPE=="PUBLIC"){
                    var encryptedEmail = Buffer.from(process.env.APITOKEN_USER).toString('base64');
                    var encryptedClave = Buffer.from(process.env.APITOKEN_PASSWORD).toString('base64');

                    objCredentials = {
                        email: encryptedEmail,
                        clave: encryptedClaveD,
                    }
                } else {
                    var encryptedEmail = Buffer.from(objCredentials.email).toString('base64');
                    var encryptedClave = Buffer.from(objCredentials.clave).toString('base64');

                    objCredentials = {
                        email: encryptedEmail,
                        clave: encryptedClave,
                    }
                }
            }

            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);
            var httpResponse = await HTTPHelper.httpTokenRequest(objCredentials,ENV_APIDEBUG);
            var responseStatus = httpResponse.status;
            var responseMessage = httpResponse.message;
            var responseData = httpResponse.data.response;

            switch(responseStatus) {
                case 200:
                    // 1. Se realizó CON ÉXITO el REQUEST TOKEN del AccessToken.
                    var newAccessToken = responseData.access_token;
                    var newRefreshToken = responseData.refresh_token;

                    switch(ROUTE_ACCESSTYPE) {
                        case "PUBLIC":
                            store.dispatch('authPublicStore/setAccessToken',newAccessToken);
                            store.dispatch('authPublicStore/setRefreshToken',newRefreshToken);
                            break;
                        case "SESSION":
                            store.dispatch('authSessionStore/setAccessToken',newAccessToken);
                            store.dispatch('authSessionStore/setRefreshToken',newRefreshToken);
                            break;
                        default:
                            throw Error("Invalid ACCESS TYPE = '"+accessType+"'");
                    }

                    break;

                default:
                    throw new Error(responseMessage);
            }

            return true;

        } catch (error) {
            throw Error("[TokenLogic] request()::ERROR >>> "+error);
        }
    }

    async refresh(){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var refreshToken = store.getters['authPublicStore/getRefreshToken'];

            if(API_ACCESSTYPE=="SESSION"){
                refreshToken = store.getters['authSessionStore/getRefreshToken'];
            }

            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);
            var httpResponse = await HTTPHelper.httpTokenRefresh(refreshToken,ENV_APIDEBUG);
            var responseStatus = httpResponse.status;
            var responseMessage = httpResponse.message;
            var responseData = httpResponse.data.response;

            switch(responseStatus) {
                case 200:
                    // Se realizó CON ÉXITO el REQUEST TOKEN del AccessToken.
                    var newAccessToken = responseData.access_token;
                    var newRefreshToken = responseData.refresh_token;

                    switch(API_ACCESSTYPE) {
                        case "PUBLIC":
                            store.dispatch('authPublicStore/setAccessToken',newAccessToken);
                            store.dispatch('authPublicStore/setRefreshToken',newRefreshToken);
                            break;
                        case "SESSION":
                            store.dispatch('authSessionStore/setAccessToken',newAccessToken);
                            store.dispatch('authSessionStore/setRefreshToken',newRefreshToken);
                            break;
                        default:
                            throw Error("Invalid ACCESS TYPE = '"+accessType+"'");
                    }
                default:
                    throw new Error(responseMessage);
            }

            return true;

        } catch (error) {
            throw Error("[TokenLogic] refresh()::ERROR >>> "+error);
        }
    }

    async listarTokenAccess(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/token/access/listar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[TokenLogic] listarTokenAccess()::ERROR >>> "+error);
        }
    }

    async borrarTokenAccess(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/token/access/borrar";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[TokenLogic] borrarTokenAccess()::ERROR >>> "+error);
        }
    }

    async revocarTokenAccess(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/token/access/revocar";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[TokenLogic] borrarTokenAccess()::ERROR >>> "+error);
        }
    }

    async listarTokenRefresh(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/token/refresh/listar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[TokenLogic] listarTokenRefresh()::ERROR >>> "+error);
        }
    }

    async borrarTokenRefresh(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/token/refresh/borrar";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[TokenLogic] borrarTokenAccess()::ERROR >>> "+error);
        }
    }

    async revocarTokenRefresh(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/token/refresh/revocar";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[TokenLogic] borrarTokenAccess()::ERROR >>> "+error);
        }
    }


}

const instance = TokenLogic.getInstance();
export default instance;